import { NoSsr } from "@mui/base";
import { useAtomValue } from "jotai";
import qs from "qs";
import type { FunctionComponent } from "react";

import { IdleQueue } from "scmp-app/components/common/idle-queue";
import { useLoginDialogStateHelper } from "scmp-app/components/login-dialog/hooks";
import { config, tracking } from "scmp-app/data";
import { accountAtom } from "scmp-app/lib/account";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";

import { StyledAnonymousAvatar, StyledAvatar, StyledOffsetContainer } from "./styles";

export type Props = {
  className?: string;
};

export const HeaderAvatar: FunctionComponent<Props> = ({ className }) => {
  const { user } = useAtomValue(accountAtom);
  const { openLoginDialog } = useLoginDialogStateHelper();

  const currentPageType = useCurrentPageType();
  const postiesHeaderQueryParameters = {
    module: tracking.module.HeaderPosties,
    pgtype: currentPageType,
  };

  const redirectToAccountApp = () => {
    const url = new URL(`${config.account.scmpAccountHost}/settings`);
    url.search = qs.stringify({
      ...postiesHeaderQueryParameters,
      destination: encodeURIComponent(window.location.href),
    });
    location.href = url.toString();
  };

  const handleAnonymousAvatarClick = () => {
    const isPostiesParentPage = window.location.pathname === "/posties/parents";
    const destination = isPostiesParentPage
      ? `${window.location.origin}/posties`
      : window.location.href;

    openLoginDialog({
      application: "posties",
      destination,
      ga4CustomParameter: {
        trigger_point: "header",
      },
    });
  };

  return (
    <StyledOffsetContainer>
      <NoSsr>
        {user ? (
          <IdleQueue>
            <StyledAvatar $isLoggedIn={true} className={className} onClick={redirectToAccountApp} />
          </IdleQueue>
        ) : (
          <StyledAnonymousAvatar onClick={handleAnonymousAvatarClick} />
        )}
      </NoSsr>
    </StyledOffsetContainer>
  );
};

HeaderAvatar.displayName = "HeaderAvatar";
